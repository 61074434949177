<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <h3>سرویس‌های ارائه شده</h3>
      </v-card-title>
      <mobile-text v-if="deviceType == 'mobile'" :title="''" />

      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="timeFrom">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="timeFrom"
                    label=" ساعت از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="timeFrom"
                  element="timeFrom"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="timeTo">
                  <v-text-field
                    outlined
                    dense
                    append-icon="schedule"
                    v-model="timeTo"
                    label=" ساعت تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="timeTo"
                  element="timeTo"
                  color="#00a7b7"
                  type="time"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="primary-btn submit-btn"
                  style="float: right"
                  @click="getServices()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
            </v-row>
            <v-row v-if="role == 'reception'" class="align-items-center">
              <v-icon class="text-danger mb-2"> priority_high </v-icon>
              <p class="mt-2 text-danger">
                بازه زمانی انتخابی نمی‌تواند بیشتر از ۱۳ ساعت باشد
              </p>
            </v-row>
            <v-row v-if="role == 'manager'" class="align-items-center">
              <v-icon class="text-danger mb-2"> priority_high </v-icon>
              <p class="mt-2 text-danger">
                بازه زمانی انتخابی نمی‌تواند بیشتر از 25 ساعت باشد
              </p>
            </v-row>
            <hr />
            <v-row align-v="end" v-if="role == 'admin'">
              <v-col> </v-col>
              <v-col>
                <v-btn
                  style="float: left"
                  class="secondary-btn mb-2"
                  @click="$refs.renderedExcel.$el.click()"
                  :disabled="!Items.length"
                  >دریافت اکسل</v-btn
                >
                <vue-excel-xlsx
                  v-show="false"
                  ref="renderedExcel"
                  :data="Items"
                  :columns="excelFields"
                  :filename="
                    'لیست خدمات ارائه شده از ' + dateFrom + ' تا ' + dateTo
                  "
                  :sheetname="currentDate"
                >
                  دریافت اکسل
                </vue-excel-xlsx>
              </v-col>
            </v-row>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده سرویسی برای نمایش وجود ندارد"
              empty-filtered-text="در بازه زمانی انتخاب شده سرویسی برای نمایش وجود ندارد"
              :filter="Filter"
              :busy="Busy"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    typeof data.value === "number"
                      ? Number(data.value).toLocaleString()
                      : data.value
                  }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText,
  },
  data() {
    return {
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "date", label: "تاریخ و ساعت" },
        { key: "patientName", label: "نام بیمار" },
        { key: "doctorName", label: "پزشک" },
        { key: "nurseName", label: "پرستار" },
        { key: "name", label: "عنوان خدمات" },
        { key: "visitCost", label: "قیمت (ریال)" },
        { key: "receptionName", label: "کارمند پذیرش" },
      ],
      excelFields: [
        { field: "date", label: "تاریخ و ساعت" },
        { field: "patientName", label: "نام بیمار" },
        { field: "doctorName", label: "پزشک" },
        { field: "nurseName", label: "پرستار" },
        { field: "name", label: "عنوان خدمات" },
        { field: "visitCost", label: "قیمت (ریال)" },
        { field: "receptionName", label: "کارمند پذیرش" },
      ],
      Items: [],
      role: "",
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      busyDocPercent: false,
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
    };
  },
  methods: {
    getServices() {
      //NOTE getting services info by start date and end date when page mount
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/visits/records",
          {
            startDate: this.dateFrom + this.timeFrom,
            endDate: this.dateTo + this.timeTo,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.Items.forEach((service) => {
              if (service.status == "لغو") {
                service._rowVariant = "danger";
              }
            });
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },

  mounted() {
    this.role = localStorage.getItem("role");
    if (this.role == "reception") {
      this.dateFrom = moment(new Date())
        .subtract(13, "hour")
        .format("jYYYY/jMM/jDD");
      this.timeFrom = moment(new Date()).subtract(13, "hour").format("HH:mm");
      this.timeTo = moment(new Date()).format("HH:mm");
    }
    this.getServices();
  },
};
</script>
